/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';

import LaptopSkeletonIllustration from 'svg/illustrations/LaptopSkeleton';

const mock = [
  {
    title: 'Knowledge bases',
  },
  {
    title: 'CRM systems',
  },
  {
    title: 'Product catalogs',
  },
  {
    title: 'APIs with external systems',
  },
  {
    title: 'Websites',
  },
  {
    title: 'Enterprise Resource Planning (ERP) systems',
  },
  {
    title: 'Document repositories',
  },
  {
    title: 'Chat logs and user feedback',
  },
  {
    title: 'More...',
  },
];

const SecondBenefits = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <List disablePadding>
      {mock.map((item, index) => (
        <ListItem
          key={index}
          disableGutters
          data-aos="fade-up"
          data-aos-delay={index * 300}
          data-aos-offset={100}
          data-aos-duration={600}
        >
          {/* <ListItemAvatar>
            <Box
              component={Avatar}
              variant={'rounded'}
              color={theme.palette.primary.dark}
              bgcolor={`${theme.palette.primary.light}22`}
            >
              {item.icon}
            </Box>
          </ListItemAvatar> */}
          {/* <ListItemText 
          primaryTypographyProps={{ variant: 'p' }}
          // secondaryTypographyProps={{ variant: 'p' }}
          primary={item.title} 
          // secondary={item.subtitle} 
          /> */}
          <ul >
          <li>{item.title} </li>
         
          </ul>
        </ListItem>
      ))}
    </List>
  );

  const RightSide = () => (
    <Box width={1}>
      <Box
        sx={{
          position: 'relative',
          marginX: 'auto',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            marginX: 'auto',
          }}
        >
          <Box>
            <Box
              position={'relative'}
              zIndex={2}
              maxWidth={1}
              height={'auto'}
              sx={{ verticalAlign: 'middle' }}
            >
              <LaptopSkeletonIllustration />
            </Box>
            <Box
              position={'absolute'}
              top={'8.4%'}
              left={'12%'}
              width={'76%'}
              height={'83%'}
              border={`1px solid ${theme.palette.alternate.dark}`}
              zIndex={3}
            >
              <Box
                component={'img'}
                loading="lazy"
                src="https://cms.cognijiwa.ai/assets/66339008-88c4-42a5-a7f1-399976e9d374.png"
                alt="Image Description"
                width={1}
                height={1}
                sx={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box marginBottom={4}>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography variant="h4" align={'center'} sx={{ fontWeight: 700 }}>
          Integrating data sources to unleash possibilities
          </Typography>
        </Box>
        <Typography variant="h6" component="p" align={'center'}>
        Just how much can you stretch Cogni’s limit in customer service? The sky’s the limit! Cogni can be integrated with various systems to push the boundary of your customer service capabilities!
        </Typography>
      </Box>
      <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <LeftSide />
            {/* <Button href="/signup" variant="contained" color="primary" size="large" sx={{marginTop:20}}>
              Get started right now!
              </Button> */}
          </Box>
        </Grid>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          {/* <RightSide /> */}
          <Box
                component={'img'}
                loading="lazy"
                src="https://cms.cognijiwa.ai/assets/66339008-88c4-42a5-a7f1-399976e9d374.png"
                alt="Image Description"
                width={1}
                height={1}
                maxWidth={500}
                margin={'auto'}
                sx={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                }}
              />
        </Grid>
        
      </Grid>
      
    </Box>
  );
};

export default SecondBenefits;
